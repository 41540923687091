enum CharCodes {
  Null = 0,
  Tab = 9,
  Newline = 10,
  FormFeed = 12,
  CarriageReturn = 13,
  Space = 32,
  ExclamationPoint = 33,
  Hash = 35,
  Percent = 37,
  LeftParen = 40,
  RightParen = 41,
  Plus = 43,
  Minus = 45,
  Dash = 45,
  Period = 46,
  ForwardSlash = 47,
  Zero = 48,
  One = 49,
  Two = 50,
  Three = 51,
  Four = 52,
  Five = 53,
  Six = 54,
  Seven = 55,
  Eight = 56,
  Nine = 57,
  LessThan = 60,
  GreaterThan = 62,
  A = 65,
  D = 68,
  E = 69,
  F = 70,
  O = 79,
  P = 80,
  R = 82,
  LeftSquareBracket = 91,
  BackSlash = 92,
  RightSquareBracket = 93,
  a = 97,
  b = 98,
  d = 100,
  e = 101,
  f = 102,
  i = 105,
  j = 106,
  l = 108,
  m = 109,
  n = 110,
  o = 111,
  r = 114,
  s = 115,
  t = 116,
  u = 117,
  x = 120,
  LeftCurly = 123,
  RightCurly = 125,
  Tilde = 126,
}

export default CharCodes;
